<template>
  <div class="order-examine-popup">
    <el-dialog title="【费用审核通过】- 提醒" :close-on-click-modal="false" :visible.sync="visible" width="35%" center>
      <div>
        请确认是否继续执行【费用审核通过】操作？
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirm">确 认 继 续</el-button>
        <el-button type="primary" @click="visible = false">返 回</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data () {
    return {
      visible: false,
      row: {},
      isBatch: '', // 是否批量
      ids: ''
    }
  },
  computed: {
    isView () {
      return false
    }
  },
  methods: {
    /**
     * 点击表格变色start
     */
    selectedHighlight ({ row, rowIndex }) { 
      if ((this.getIndex) === rowIndex ) {
        return {
          'background-color': 'rgb(250, 195, 100)'
        }
      }
    },
    // 如果为已取消时，该行置灰
    tableRowClassName ({ row, rowIndex }) {
      row.index = rowIndex;
      if (row.status === 12) {
        return 'cancel-row'
      }
      return ''
    },
    rowClick (row) {
      this.getIndex=row.index
    },
    /**
     * 点击表格变色end
     */
    init (isBatch, row) {
      this.visible = true
      this.row = row
      this.isBatch = isBatch
      this.ids = []
      // 单条数据
      if(isBatch == 'single') {
        this.ids = row.id
      } else if(isBatch == 'multi') {
        // 多条数据
        row.forEach(item => {
          this.ids.push(item.id)
        })
      }
    },
    confirm () {
      let data = null
      data = {
        ids: this.ids
      }
      // if (this.isBatch == 'single') {
      //   data = {
      //     id: this.ids
      //   }
      // } else {
      //   data = {
      //     ids: this.ids
      //   }
      // }
      this.$http({
        url: this.$http.adornUrl(`/costInfo/audit`),
        method: 'post',
        data: this.$http.adornData(data)
      }).then(({ data }) => {
        if (data) {
          this.$message({
            message: '操作成功',
            type: 'success',
            duration: 1500,
            onClose: () => {
              this.visible = false
              this.$emit('refreshDataList')
            }
          })
        } else {
          this.$message.error(data.data.msg)
        }
      })
    }
  }
}
</script>
