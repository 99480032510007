<template>
  <div class="order-examine-popup">
    <el-dialog title="生成账单"  :close-on-click-modal="false" :visible.sync="visible" :width="dataForm.billType === 1 ? '30%' : '40%'" center>
      <el-form :model="dataForm" ref="dataForm" size="small" label-width="120px">
        <el-row :gutter="50">
          <el-col :span="20">
            <el-form-item label="生成对象" prop="customerId" :rules="[{ required: true, message: '请选择生成对象', trigger: 'change' }]">
              <el-select class="item-choose" v-model="dataForm.customerId" style="width: 100%;" filterable
                         placeholder="请选择">
                <el-option label="全部" value="all"></el-option>
                <el-option
                  v-for="item in customerList"
                  :key="item.id"
                  :label="item.simpleName"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50" v-if="dataForm.billType === 1">
          <el-col :span="24">
            <el-form-item label="提货日期" prop="takeGoodsDate" :rules="[{ required: true, message: '请选择提货日期', trigger: 'change' }]">
              <el-date-picker
                v-model="dataForm.takeGoodsDate"
                align="right"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
                :picker-options="dayPickerOptions">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50" v-else>
          <el-col :span="24">
            <el-form-item label="提货日期" prop="dateRange" :rules="[{ required: true, message: '请选择提货日期', trigger: 'change'}]">
              <el-date-picker
                v-model="dataForm.dateRange"
                type="daterange"
                align="right"
                unlink-panels
                range-separator="-"
                value-format="yyyy-MM-dd"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :picker-options="monthPickerOptions">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirm">确 认</el-button>
        <el-button type="primary" @click="visible = false">返 回</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data () {
    return {
      visible: false,
      dataForm: {
        billType: null,
        customerId: null,
        takeGoodsBeginDate: null,
        takeGoodsEndDate: null,
        takeGoodsDate: null,
        dateRange: null
      },
      customerList: [],
      dayPickerOptions: {
        disabledDate (time) {
          return time.getTime() > Date.now()
        },
        shortcuts: [{
          text: '今天',
          onClick (picker) {
            picker.$emit('pick', new Date())
          }
        }, {
          text: '昨天',
          onClick (picker) {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24)
            picker.$emit('pick', date)
          }
        }, {
          text: '一周前',
          onClick (picker) {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', date)
          }
        }]
      },
      monthPickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      }
    }
  },
  methods: {
    init (billType, type) {
      this.visible = true
      this.dataForm.billType = billType
      this.dataForm.type = type
      this.dataForm.customerId = 'all'
      if (type == 1) {
        this.getCustomerList()
      } else {
        this.findListBySelect()
      }
    },
    getCustomerList () {
      this.$http({
        url: this.$http.adornUrl('/customer/findAll'),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({data}) => {
        this.customerList = data
      })
    },
    findListBySelect () {
      this.$http({
        url: this.$http.adornUrl('/motorcade/findListBySelect'),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({data}) => {
        this.customerList = data
      })
    },
    confirm () {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          if (this.dataForm.billType > 1) {
            let takeGoodsTime = this.dataForm.dateRange
            if (takeGoodsTime && takeGoodsTime.length === 2) {
              this.dataForm.takeGoodsBeginDate = takeGoodsTime[0]
              this.dataForm.takeGoodsEndDate = takeGoodsTime[1]
            }
          } else {
            this.dataForm.takeGoodsBeginDate = this.dataForm.takeGoodsDate
            this.dataForm.takeGoodsEndDate = this.dataForm.takeGoodsDate
          }
          this.$http({
            url: this.$http.adornUrl(`/costInfo/genOrderCostBill`),
            method: 'POST',
            data: {
              billType: this.dataForm.billType,
              costType: this.dataForm.type,
              customerId: this.dataForm.customerId,
              takeGoodsBeginDate: this.dataForm.takeGoodsBeginDate,
              takeGoodsEndDate: this.dataForm.takeGoodsEndDate
            }
          }).then(({ data }) => {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1000,
              onClose: () => {
                if (this.dataForm.customerId === 'all') {
                  this.dataForm.customerId = null
                }
                this.visible = false
                this.$emit('refreshDataList')
              }
            })
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>
